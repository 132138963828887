.Tilt {
  transition: transform 0.2s ease-in-out;
}
.Tilt:hover {
  transform: scale(1.05);
}
.Tilt-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
.card {
  width: 100%;
  max-width: 300px;
}
.card-img-top {
  height: 75%;
  object-fit: cover;
}
.card-body {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}
.card-text {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}
.card-text li {
  margin-bottom: 0.5rem;
}
.card-time-range {
  font-size: 0.875rem;
  color: #6c757d;
}

.card-container-section {
  padding: 70px 0;
}
.card-container-section .container {
  background-color: #f8f9fa;
  border-radius: 15px;
}
.card-container-section .card {
  width: 100%;
  max-width: 300px;
}

.custom-btn {
  width: 200px;
  height: 60px;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #adb5bd;
  border: none;
  border-radius: 10px;
  color: white;
  transition: background-color 0.3s ease;
}
.custom-btn:hover {
  background-color: #868e96;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(71, 77, 82, 0.5);
}

.timeline-card {
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}
.card-img-collage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}
.collage-img {
  flex: 1 1 calc(33.333% - 10px);
  margin: 5px;
  max-width: calc(33.333% - 10px);
  border-radius: 10px;
}

.timeline-page {
  position: relative;
  overflow: hidden;
}

.timeline-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.timeline-image {
  flex: 1 1 calc(33.333% - 10px);
  margin: 5px;
  max-width: calc(33.333% - 10px);
  border-radius: 10px;
}

.vertical-timeline-element-content {
  margin-bottom: 100px !important;
}

.vertical-timeline::before {
  background: #007bff !important;
}

.card-small {
  width: 150px;
  height: auto;
}

.card-small .card-img-top {
  height: 100px;
  object-fit: cover;
}

.card-small .card-body {
  padding: 0.5rem;
}

.card-small .card-title {
  font-size: 1rem;
}

.card-small .card-text {
  font-size: 0.875rem;
}

/* Valentines.css */
.valentines-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ff74b9;
  overflow: hidden;
  position: relative;
}

.content {
  text-align: center;
  color: white;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.yes-button,
.no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.yes-button {
  background-color: green;
  color: white;
}

.no-button {
  background-color: red;
  color: white;
}

.heart-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.heart, .emoji-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease, transform 0.2s ease;
}

h1 {
  color: #FF69B4;
}